<template>
  <CContainer class="d-flex justify-content-center mt-5 page404">
    <CRow class="justify-content-center">
      <CCol md="12">
        <!--<div class="text-left mt-4" style="font-size: 18px">
          ページが見つかりませんでした。
        </div>-->
        <div class="box-content d-flex flex-column px-3">
          <div class="clearfix">
            <div class="d-flex justify-content-center text-align-center">
              <img
                src="@/assets/img/page_notice.png"
                alt=""
                style="object-fit: contain; width: 70%; height: 100%"
              />
            </div>
            <div
              class="font-weight-bold text-left mx-auto mb-5 text-muted mt-5 container"
              style="font-size: 22px; width: 80%"
            >
              <p v-if="title">すでに {{ item }} をお持ちのようです!</p>
              <p>
                <a :href="top_page">{{ top_page }}</a>
                に表示されているかご確認ください。
              </p>
              <p>
                もし表示されていない場合は、
                <a :href="inquiry_page">{{ inquiry_page }}</a>
                までご相談くださいませ。
              </p>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { Constants } from "../../utils/constants";

export default {
  name: "PageNotice",
  data() {
    return {
      dataShop: [],
      page_404: null,
      homepage: window.location.origin,
      shop_id: this.$route.params.shopId,
      htmlText: "",
      item: "",
      top_page: "",
      inquiry_page: "",
      id: "",
      title: "",
    };
  },
  created() {
    var inputs = JSON.parse(localStorage.getItem(Constants.INFO_CONTENT));
    this.id = inputs.id;
    this.title = inputs.title;
    const hostName = window.location.origin;
    this.item = this.title;
    this.top_page = this.$route.params.shopId
      ? hostName + "/shop/" + this.$route.params.shopId
      : hostName;
    this.inquiry_page = this.$route.params.shopId
      ? hostName + "/shop/" + this.$route.params.shopId + "/inquiry"
      : hostName + "/inquiry";
  },
};
</script>
<style lang="scss" scoped>
.link-home-page:hover {
  color: #aaa !important;
}
.default-layout .page404 .box-content {
  width: 823.6px;
  margin: 10px auto;
  box-shadow: 0px 3px 6px #7681929c !important;
  border-radius: 20px !important;
}
@media screen and (max-width: 991px) {
  .default-layout .page404 .box-content {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .container {
    width: 100% !important;
    font-size: 16px !important;
  }
}
@media (min-width: 991px) {
  .container {
    width: 100% !important;
  }
}
</style>
